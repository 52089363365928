import React, { useState, useEffect, useRef, useContext } from "react";
import disableDevtool from 'disable-devtool';
import axios from "axios";
import { cl } from "./components/functions";
//import publicIp from  'public-ip';

/* export default class Footer extends Component {

    render() {

        // (async () => {
        //     global.gIp = await publicIp.v4(); 
        //     console.log(global.gIp);
        //     // let p_ip = document.getElementById('p_ip');
        //     // p_ip.innerHTML = "Ip: "+global.gIp;
        //     //=> '46.5.21.123'

        //     //console.log(await publicIp.v6());
        //     //=> 'fe80::200:f8ff:fe21:67cf'
        // })();

        /* declare interface optionStatic {
md5?: string; // Bypass the disabled md5 value, see 3.2 for details, the bypass disable is not enabled by default
url?: string; // Jump to the page when closing the page fails, the default value is localhost
tkName?: string; // Bypass the url parameter name when disabled, the default is ddtk
ondevtoolopen?(): void; // Callback for opening the developer panel, the url parameter is invalid when enabled
interval?: number; // Timer interval is 200ms by default
disableMenu?: boolean; // Whether to disable the right-click menu The default is true
stopIntervalTime?: number; // Waiting time to cancel monitoring on mobile
} */

/*         let url = 'https://riluk.com.br/nodev/?n=' + global.gNomeCurto + '&e=' + global.gEmail + '&ip=' + global.gIp

        disableDevtool({
            url: url
        });

        if ((global.gVersion) && (global.gNewVersion) && (global.gVersion !== global.gNewVersion)) {
            let msg = "Nova Atualização disponível do sistema, versão " + global.gNewVersion + " , saia do sistema e atualize seu navegador agora!";
            window.alert(msg);
            //window.location.href = "/login";
        }

        return (
            <div>
                <footer className="main-footer">
                    Copyright ©2023 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/desenvolvimento">   You Evolution </a>.
                    <div className="float-right d-sm-inline-block">
                        <b>Versão</b> {global.gVersion}
                    </div>
                </footer>


            </div>
        )
    }
} */

function Gerador(params) {

    const [ip, setIP] = useState("");
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        //console.log(res.data);
        setIP(res.data.ip);
        global.gIp = res.data.ip;
    };
    getData();

    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
    }, []);

    /* declare interface optionStatic {
    md5?: string; // Bypass the disabled md5 value, see 3.2 for details, the bypass disable is not enabled by default
    url?: string; // Jump to the page when closing the page fails, the default value is localhost
    tkName?: string; // Bypass the url parameter name when disabled, the default is ddtk
    ondevtoolopen?(): void; // Callback for opening the developer panel, the url parameter is invalid when enabled
    interval?: number; // Timer interval is 200ms by default
    disableMenu?: boolean; // Whether to disable the right-click menu The default is true
    stopIntervalTime?: number; // Waiting time to cancel monitoring on mobile
    } */

    //let url = 'https://app.riluk.com.br/anodev/detect.php?n=' + global.gNomeCurto + '&e=' + global.gEmail + '&ip=' + global.gIp
    
    //let whref = window.location.href;
    //let wpathname = window.location.pathname;
    let whost = window.location.hostname.toString();

    let whref =  'nodev';

    //cl("whost",whost)

    //let url = 'https://app.riluk.com.br/nodev';
    let url = whref;

    if(whost !== 'localhost'){

        disableDevtool({ url: url });
    }
    
    //console.log("habilitar disableDevtool");
    //cl("window.location.href",window.location.href)
    //cl("window.location.hostname",window.location.hostname)
    //cl("window.location.pathname",window.location.pathname)

    return (
        <div>
            <footer className="main-footer">
                Copyright ©2023 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/desenvolvimento">   You Evolution </a>.
                <div className="float-right d-sm-inline-block">
                    <b>Versão</b> {global.gVersion}
                </div>
            </footer>


        </div>
    )

}
export default Gerador