import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import BrowserRouter from "./routes"
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './gerCotacoes.css';
import Loading from "./assets/loading4.gif";
import { cl } from './components/functions';
//var cors = require('cors')
// import Header from './Header';
// import Menu from './Menu';
// import Footer from './Footer';
// import Content from './Content';
const TOKEN_KEY = "KEY_APP_BGG";

/* declare interface optionStatic {
    md5?: string; // Bypass the disabled md5 value, see 3.2 for details, the bypass disable is not enabled by default
    url?: string; // Jump to the page when closing the page fails, the default value is localhost
    tkName?: string; // Bypass the url parameter name when disabled, the default is ddtk
    ondevtoolopen?(): void; // Callback for opening the developer panel, the url parameter is invalid when enabled
    interval?: number; // Timer interval is 200ms by default
    disableMenu?: boolean; // Whether to disable the right-click menu The default is true
    stopIntervalTime?: number; // Waiting time to cancel monitoring on mobile
} */


function confirmSair(num, tipo, total, chave) {
  //    var testeStr = 'Isso é um teste <<nome>>';
  //    testeStr = testeStr.replace('<<nome>>', nome)
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
          <h3>Sair</h3><br></br><br></br>
          <p id='pmodal'>bla bla bla ?</p>
          <br></br>
          <button className="btn bg-gradient-info btn-sm"
            onClick={() => {
              window.open("/", "_self");
              onClose();
            }}>Não</button>
          <button
            className="btn bg-gradient-danger btn-sm float-right"
            onClick={() => {
              flogout();
              onClose();
            }}
          >
            Sim
          </button>
        </div>
      );
    }
  });

}

function inicial(params) {

  confirmSair();

  return (
    <>
      <div className="pull-right">
        <Link to="" className="btn btn-default btn-flat " data-toggle="modal" data-target="#modal-default">Sair</Link>
      </div>

    </>
  )

}
function flogout(params) {
 

  let url = 'https://app.riluk.com.br/anodev/detect.php?n=' + global.gNomeCurto + '&e=' + global.gEmail + '&ip=' + global.gIp
//cl("url",url)

  if(window.location.hostname ==='localhost'){
    alert("desabilite o disableDevtool")
    return
  }

  sessionStorage.removeItem(TOKEN_KEY);
//cl("removed key",url);
  window.open(url, "_self");
//cl("redirect")
  //window.open("/login", "_self");

}

function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
} 

let ii=0;

function chamalogout(params) {

  
  if ((global.gNomeCurto) || (ii > 5)){  
  //cl("chamalogout")
    flogout();
  } else {
    delay(1000);
    ii = ii +1;
  //cl("ii",ii)
    chamalogout();
  }
  
}

const App = () => {
  //console.log("logout");

  {/* <Navigate to="/login" />; */ }
  //this.props.history.push("/login");

  //confirmSair();
  const [ip, setIP] = useState("");
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      //cl("DelNCtc first3 false")
      return;
    }
    chamalogout(); 
  }, [ip])

  const getData = async () => {
      const res = await axios.get("https://api.ipify.org/?format=json");
      //console.log(res.data);
      global.gIp = res.data.ip;
      setIP(res.data.ip);
  };
  getData();


  return (


    <div className="content-wrapper">
    <div className="container-login100-form-btn">
      <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

    </div>
    <div className="text-center p-t-30">Aguarde...</div>
  </div>



  )

};
//App.use(cors())

export default App;


// export default class App extends Component {
//   render() {
//     return (
//       <div>
//       <Header />
//       <Menu/>
//       <Content/>
//       <Footer/>
//       </div>
//     )
//   }
// }
