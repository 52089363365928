import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/MB_Logo.png";
import "./components/functions";
import { cl } from "./components/functions";
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import './gerCotacoesProf.css';
const TOKEN_KEY = "KEY_APP_BGG";


async function trocarsenha(params) {
  
  try {
    var bodyFormData = new FormData();
    //localStorage.setItem('linkbase', 'https://api.espacomarciobagagine.com.br');
    //const klinkbase = localStorage.getItem('linkbase');
    //global.gLinkBase = klinkbase;
    //console.log("linkbase",klinkbase);
    bodyFormData.set('apikey', '3987MS0SJF09SFMG966505786V9UVN83CFNJH');
    bodyFormData.set('chave', global.rchave);
    bodyFormData.set('pass', document.getElementById("password").value);
    //bodyFormData.append('password', 'senha');
    const response = await axios({
      method: 'post',
      url: 'https://api.espacomarciobagagine.com.br/resetsenha.php',
      //url: 'http://192.168.15.200:8085/appyou/login.php?apikey=3987MS0SJF09SFMG966505786V9UVN83CFNJH',
      // url: 'http://192.168.99.250:3333/loginusers',
      data: bodyFormData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    });

    //const response = await api.post("/loginusers2", { email, password });
    console.log("resp",response.data);
    if (response.data) {
      document.getElementById('pError').innerHTML='Senha Alterada !!!';
      console.log("login-: ", response.data);
      //loginapi(response.data);
      //irHome();
      const teste = sessionStorage.removeItem(TOKEN_KEY);
      window.open("/", "_self");

    } else {

      document.getElementById('pError').innerHTML='Chave não localizada !';

    }
  } catch (err) {
    console.log(err);
    if (!err.response) {

    } else {

    }

  }

}


function Gerador() {

  const { idchave } = useParams();
  let echave = document.getElementById('chave');
  if (echave) { echave.value = idchave; }
  global.rchave = idchave;
  cl("idchave", idchave)

  function gravar(params) {
    let ebuttonLogin = document.getElementById('buttonLogin');
    if (ebuttonLogin) { ebuttonLogin.style.display = 'none'; }
    let ebuttonLoading = document.getElementById('buttonLoading');
    if (ebuttonLoading) { ebuttonLoading.style.display = ''; }

    let epassword = document.getElementById('password');
    let epassword2 = document.getElementById('password2');

    if ((epassword.value.length < 6) || (epassword.value.length < 6)) {
      window.alert("Senha precisa conter 6 caracteres no mínimo !")
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      return
    }

    if (epassword.value === epassword2.value) {

      trocarsenha();

      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }

    } else {
      window.alert("Senha não são iguais, favor revisar !")
      epassword.value = '';
      epassword2.value = '';
      epassword.focus();
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      return
    }
  }

  function CaixaFechado(params) {
    return (
      <div>

        <div className="limiter">
          <div className="container-login100" style={{ backgroundImage: 'url("../images/bg-2.jpg")' }}>
            <div className="wrap-login100">
              <span className="login100-form-logo">
                <img className="imglogo" src={Logo} alt="Login" />
                {/*<i className="zmdi zmdi-landscape" />*/}
              </span>
              <span className="login100-form-title p-b-76 p-t-27">
                {/* JVF - APP */}
              </span>
              <div className="wrap-input100 validate-input" data-validate="Enter username">
                <input className="input100" type="input" name="chave" id="chave" disabled value={global.rchave} autoComplete="off" placeholder="Chave de Reset" required onChange={e => this.setState({ chave: e.target.value })} />
                <span className="focus-input100" data-placeholder="🔑" />
              </div>
              <div className="validate-input wrap-input100 " data-validate="Enter password">
                <input className="input100" type="password" name="password" id="password" autoComplete="off" placeholder="Nova Senha" required />
                <span className="focus-input100" data-placeholder="" />
              </div>
              <div className="validate-input wrap-input100 " data-validate="Enter password">
                <input className="input100" type="password" name="password2" id="password2" autoComplete="off" placeholder="repetir a senha" required />
                <span className="focus-input100" data-placeholder="" />
              </div>
              <span className="spanobs">** tamanho mínimo 6 caracteres</span>
              {/*}  <div className="contact100-form-checkbox">
                              <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                              <label className="label-checkbox100" htmlFor="ckb1">
                                  Remember me
                          </label>
    </div>*/}
              <div className="container-login100-form-btn">
                <button onClick={() => gravar()} className="login100-form-btn" id="buttonLogin" name="buttonLogin">

                  <i className="fa fa-cube" aria-hidden="true"></i>
                  &nbsp;<span id="spanLogin">Alterar</span>
                </button>
                <button type="" className="login100-form-btn" id="buttonLoading" name="buttonLoading" style={{ display: 'none' }}>

                  <span id="spanLogin"><img id="imgloading" width="50px" alt="Loading.." src={Loading}></img>Aguarde</span>

                </button>
              </div>

              <div className="text-center p-t-20">
                 <p className="pError" id="pError"></p>

              </div>
              {/* <div className="text-center p-t-30">
                  <a className="txt1" href="/">
                    Esqueceu a senha ?
  </a>
                </div> */}
            </div>
          </div>
        </div>
        <div id="dropDownSelect1" />

      </div>
    )

  }


  return (
    <div>
      {CaixaFechado()}
    </div>
  )

}

export default Gerador   