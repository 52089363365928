import React from 'react'
import BrowserRouter from "./routes"
import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './gerCotacoes.css';
import Loading from "./assets/loading4.gif";
//var cors = require('cors')
// import Header from './Header';
// import Menu from './Menu';
// import Footer from './Footer';
// import Content from './Content';
const TOKEN_KEY = "KEY_APP_BGG";

function confirmSair(num, tipo, total, chave) {
  //    var testeStr = 'Isso é um teste <<nome>>';
  //    testeStr = testeStr.replace('<<nome>>', nome)
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
          <h3>Sair</h3><br></br><br></br>
          <p id='pmodal'>Deseja sair do APP ?</p>
          <br></br>
          <button className="btn bg-gradient-info btn-sm"
            onClick={() => {
              window.open("/", "_self");
              onClose();
            }}>Não</button>
          <button
            className="btn bg-gradient-danger btn-sm float-right"
            onClick={() => {
              flogout();
              onClose();
            }}
          >
            Sim
          </button>
        </div>
      );
    }
  });

}

function inicial(params) {

  confirmSair();

  return (
    <>
      <div className="pull-right">
        <Link to="" className="btn btn-default btn-flat " data-toggle="modal" data-target="#modal-default">Sair</Link>
      </div>

    </>
  )

}
function flogout(params) {

  const teste = sessionStorage.removeItem(TOKEN_KEY);
  if (teste) { };

  window.open("/login", "_self");

}


const App = () => {
  //console.log("logout");

  {/* <Navigate to="/login" />; */ }
  //this.props.history.push("/login");

  confirmSair();
  return (


    <div className="content-wrapper">
    <div className="container-login100-form-btn">
      <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

    </div>
    <div className="text-center p-t-30">Aguarde...</div>
  </div>



  )

};
//App.use(cors())

export default App;


// export default class App extends Component {
//   render() {
//     return (
//       <div>
//       <Header />
//       <Menu/>
//       <Content/>
//       <Footer/>
//       </div>
//     )
//   }
// }
