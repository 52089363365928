/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import IcoPdf from "./assets/ico_pdf.png";
//import Logo from "./assets/DreyfusLogoSite.png";
//import LineBar from "./assets/giphy.gif";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './gerCotacoes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
//import * as jsPDF from 'jspdf';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { scrollTo } from 'scroll-js';
//import { tr } from "date-fns/esm/locale";
//import gerContrato from "./gerContrato";
import copycli from "copy-to-clipboard";
import PHE from "print-html-element";
//import * as jsPDF from 'jspdf'
//import {saveAs} from "file-saver";
//import Embed from 'react-embed';
//import { data } from "jquery";

var gGlobal = require('./components/globalvar.js');
//const clipboardy = require('clipboardy');

//import html2canvas from "html2canvas";
//import { func } from "prop-types";
//import { ReadStream } from "tty";

//import '@fortawesome/fontawesome-free/css/all.min.css';
//import "bootstrap-css-only/css/bootstrap.min.css";
//import "mdbreact/dist/css/mdb.css";
//import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon, MDBBadge, MDBContainer, MDBRow, MDBCol} from "mdbreact";


//const modar = require('bootstrap');


//import Form from "./Form";


const dateFormat = require("dateformat");


global.makeChave = function (length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// //cl("inpAddFrmC",global.inpAddFrmC);

// global.inpAddFrmC.inputNome=false;
// //cl("inpAddFrmC alt",global.inpAddFrmC);

// function b64DecodeUnicode(str) {
//   return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
//   }).join(''))
// }


// // function b64EncodeUnicode(str) {
// //     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
// //         return String.fromCharCode(parseInt(p1, 16))
// //     }))
// // }
// const TOKEN_KEY = "KEY_APP_BGG";
// const token = localStorage.getItem(TOKEN_KEY);
// const base64Url = token.split('.')[1];
// //cl("base",base64Url);
// const decodedValue = JSON.parse(b64DecodeUnicode(base64Url));
// console.log("decode",decodedValue);
// const novoObj = decodedValue;
// ////cl("novoObj",novoObj);
// cl('doc',novoObj[0].doc);



function Gerador() {
  const [rep, setrep] = useState([]);
  const [reprop, setreprop] = useState([]);
  const [Atu, setAtu] = useState([]);
  const [AddNCtc, setAddNCtc] = useState([]);
  const [AddNProp, setAddNProp] = useState([]);
  const [ListProp, setListProp] = useState([]);
  const [DelNCtc, setDelNCtc] = useState([]);
  const [DelProp, setDelProp] = useState([]);
  const [GerBoleto, setGerBoleto] = useState([]);
  const [ViewPdf, setViewPdf] = useState([]);
  const isFirstRun = useRef(true);
  //add050523
  const [SelGCond, setSelGCond] = useState([]);
  const [linkQuest, setlinkQuest] = useState('');
  const [sendMsgEmail, setsendMsgEmail] = useState([]);
  const isFirstRun2 = useRef(true);
  const isFirstRun3 = useRef(true);
  const isFirstRun4 = useRef(true);
  const isFirstRun5 = useRef(true);
  const isFirstRun6 = useRef(true);
  const isFirstRun7 = useRef(true);
  const isFirstRun8 = useRef(true);
  //const [viewQuestion, setviewQuestion] = useState([]);
  const [controllerQuestion, setCQuestion] = useState('');
  const isQuestion = useRef(true);
  global.isQuestionKey = '';



  //useEffects

  useEffect(() => {
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '4326356426542564563465463445');
      bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: global.gLinkBase + '/rest.php?apikey=3987MS0SJF09SFMG966505786V9UVN83CFNJH',
        //url: 'https://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        const redata = response['data'];
        //cl('redata', redata);

        setrep(redata);
        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; }
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [Atu])


  useEffect(() => {
    //cl("entrouuuuuuu")
    if (controllerQuestion === '' | controllerQuestion === null) {
      //cl("saiuuu ifff")
      return;
    }
    //cl("passouuuuuuu", global.isQuestionKey)
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '4326356426542564563465463445');
      bodyFormData.set('chavekey', controllerQuestion);
      const response2 = await axios({
        method: 'post',
        url: 'https://admriluk/listQuestions.php?apikey=398738497834758934759834758934',
        //url: 'https://jvfapp.ddns.net:3333/listQuestions',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("resp data", response2['data'])
      if (!ignore) {
        const redata2 = response2['data'];
        //cl("redata", redata2);

        let urlfull = 'https://admriluk/rest.php?apikey=398738497834758934759834758934&cnpj=' + redata2.cnpj;
        const response3 = await axios({
          method: 'get',
          url: urlfull,
          config: { headers: { 'Content-Type': 'Authorisation' } }
        })
        //cl("resp data receita", response3['data'])
        const redata3 = response3['data'];
        //await setviewQuestion(redata);
        let ele = document.getElementById('panelQuest');
        ele.style.display = '';
        let eleLoading = document.getElementById('divLoad');
        eleLoading.style.display = 'none';
        let eleCard = document.getElementById('cardGridBgg');
        eleCard.classList.add("collapsed-card");
        let eleCardI = document.getElementById('cardGridBggI');
        eleCardI.classList.remove("fa-minus");
        eleCardI.classList.add("fa-plus");
        let eleCardbody = document.getElementById('cardGridBggBody');
        eleCardbody.style.display = 'none';

        let eleQH = document.getElementById('headCQuestion');
        //eleQH.textContent = 'Questionário Respondido - ' + redata2.nome + " " + redata2.sobrenome + " - CNPJ " + formataCnpj(redata2.cnpj) ;
        eleQH.textContent = 'Resposta de : ' + redata2.nome + " " + redata2.sobrenome + " - " + redata3.nome;


        let ele2 = document.getElementById('labeltest');

        if (redata2) {
          global.Finalidade = redata2.finalidade;
          global.QtdTorres = redata2.qtdTorres;
          global.QtdUniCom = redata2.qtdUniCom;
          global.QtdUniRes = redata2.qtdUniRes;
          ele2.innerHTML = "<div align='center'>Finalidade:  <b><font color='red'>" + redata2.finalidade + "</font></b></div><br>"
          ele2.innerHTML += "Contato: <b>" + redata2.nome + " " + redata2.sobrenome + " </b>"
          ele2.innerHTML += "Fone Fixo: <b>" + redata2.foneFixo + "</b> Celular: <b>" + redata2.foneCel + "</b><br>"
          ele2.innerHTML += "CNPJ: <b>" + formataCnpj(redata2.cnpj) + "</b><br>"
          ele2.innerHTML += "Existem áreas comum locadas para terceiros ? <b>" + redata2.areaComuns + "</b><br>"
          ele2.innerHTML += "A movimentação bancária atual, utiliza Conta Corrente: <b>" + redata2.cc + "</b><br>"
          ele2.innerHTML += "Funcionários: <b>" + redata2.func + "</b><br>"
          ele2.innerHTML += "Quantidades : Torres=<b>" + redata2.qtdTorres + "</b> Und.Res.=<b>" + redata2.qtdUniRes + "</b> Und.Com.=<b>" + redata2.qtdUniCom + "<br>"
          ele2.innerHTML += "Período a ser auditado é de : <b>" + dateFormat(redata2.dtIn, "UTC:dd/mm/yyyy") + "</b> até <b>" + dateFormat(redata2.dtAte, "UTC:dd/mm/yyyy") + "</b><br>"
          ele2.innerHTML += "Qual a quantidade de pastas ou livros foram emitidos por mês a ser auditado ? <b>" + redata2.qtdPastas + "</b><br>"
          ele2.innerHTML += "Qual a quantidade aproximada de páginas em cada pasta ou livro <b>? " + redata2.qtdPaginas + "</b><br>"
          ele2.innerHTML += "O condomínio já foi auditado anteriormente?: <b>" + redata2.jaAuditado + "</b><br>"
          ele2.innerHTML += "O síndico do período a ser auditado é ou era : <b>" + redata2.sindicoAtual + "</b><br>"
          ele2.innerHTML += "Nome e endereço da administradora responsável pelo período que será auditado? <b>" + redata2.endAdm + "</b><br>"
          ele2.innerHTML += "Descreva resumidamente os motivos pelos quais ensejaram a intenção da contratação de auditoria : <b>" + redata2.motivos + "</b><br>"
          ele2.innerHTML += "Qual o Prazo para apresentação da proposta ?: <b>" + dateFormat(redata2.prazo, "UTC:dd/mm/yyyy") + "</b><br>"
          ele2.innerHTML += "Qual a data prevista para a definição da empresa que irá ser contratada para a realização da auditoria: <b>" + dateFormat(redata2.dtPrevista, "UTC:dd/mm/yyyy") + "</b><br>"
          ele2.innerHTML += "Qual a sua participação no condomínio? <b>" + redata2.particip + "</b><br><br>"
          ele2.innerHTML += "<b> Dados na Receita (CNPJ) atualizado até " + dateFormat(redata3.ultima_atualizacao, "UTC:dd/mm/yyyy") + "</b><br>"
          ele2.innerHTML += "Nome: <b>" + redata3.nome + "</b><br>"
          ele2.innerHTML += "Tipo: <b>" + redata3.tipo + "</b><br>"
          ele2.innerHTML += "Situacao: <b>" + redata3.situacao + "</b><br>"
          ele2.innerHTML += "End: <b>" + redata3.logradouro + "," + redata3.numero + " " + redata3.bairro + " - CEP " + redata3.cep + " - " + redata3.municipio + "/" + redata3.uf + "</b><br>"
          ele2.innerHTML += "Natureza Jurídica: <b>" + redata3.natureza_juridica + "</b><br>"
          ele2.innerHTML += "Status: <b>" + redata3.status + "</b><br>"
          ele2.innerHTML += "Atividade Principal: <b>" + redata3.atividade_principal[0]['code'] + " - " + redata3.atividade_principal[0]['text'] + "</b><br>"
          //cl("redata3.qsa",redata3.qsa)
          if (redata3.qsa.length > 0) {
            ele2.innerHTML += "Quadro: <b>" + redata3.qsa[0]['qual'] + " - " + redata3.qsa[0]['nome'] + "</b><br>"
            var resultado = [];

            function percorrer(obj) {
              for (var propriedade in obj) {
                if (obj.hasOwnProperty(propriedade)) {
                  if (typeof obj[propriedade] == "object") {
                    percorrer(obj[propriedade]);
                  } else {
                    resultado.push(obj[propriedade]);
                  }
                }
              }
            }

            percorrer(redata2);
            console.log(JSON.stringify("resultado", resultado));
          }
          // const stringifynew = require("json-stringify-pretty-compact");
          // ele2.innerHTML += stringifynew(redata3, {maxLength: Infinity});
          //ele2.innerHTML += JSON.stringify(redata3, null, 2);
          // ele2.innerHTML += JSON.stringify(redata2)
        } else { ele2.textContent = 'Não encontrado' }
        let eleTipoI = document.getElementById('txtTipoI');
        let eleTipoP = document.getElementById('txtTipoP');
        let eleTipoG = document.getElementById('txtTipoG');
        let eleLTipoP = document.getElementById('labelTipoP');
        let eleLTipoI = document.getElementById('labelTipoI');
        //cl('finalidade', redata2.finalidade);
        switch (redata2.finalidade) {
          case 'Auditoria Investigativa':
            eleTipoI.checked = true;
            break;
          case 'Auditoria Preventiva':
            eleTipoP.checked = true;
            break;
          case 'Auditoria de Gestão':
            eleTipoG.checked = true;
            break;
          case 'Auditoria Investigativa e Preventiva':
            eleTipoI.checked = true;
            eleLTipoP.style.color = "#ff0000";
            eleLTipoI.style.color = "#ff0000";

          //cl('entrou set color');
            break;
          default:
            //eleTipoI.checked = true;
            break;

        }
        let eleDtIni = document.getElementById('inputDtIni');
        eleDtIni.value = redata2.dtIn;
        let eleDtFim = document.getElementById('inputDtFim');
        eleDtFim.value = redata2.dtAte;
        let eleDifDias = document.getElementById('inputDifDias');
        let difDias = calculaDias(dateFormat(redata2.dtIn, "dd/mm/yyyy"), dateFormat(redata2.dtAte, "dd/mm/yyyy"));
        eleDifDias.value = difDias;
        let eleMeses30 = document.getElementById('inputDiasMeses30');
        let M30 = Math.round(difDias / 30);
        eleMeses30.value = M30;
        let eleTPags = document.getElementById('inputTPags');
        eleTPags.value = redata2.qtdPaginas;
        let eleTUnids = document.getElementById('inputTUnids');
        let TUnids = (parseInt(redata2.qtdUniRes) + parseInt(redata2.qtdUniCom));
        eleTUnids.value = TUnids;

        let eleTDiasPasta = document.getElementById('inputTDiasPasta');
        let TDiasPasta = 2;
        eleTDiasPasta.value = TDiasPasta;
        let elePrazo = document.getElementById('inputPrazo');
        let Prazo = (TDiasPasta * M30);
        elePrazo.value = Prazo;


        let eleVUnit = document.getElementById('inputVUnit');
        let Vunit = 450;
        eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleVTotal = document.getElementById('inputVTotal');
        let VTotal = (M30 * Vunit);
        eleVTotal.value = VTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let elePorUnids = document.getElementById('inputPorUnids');
        let PorUnids = (VTotal / TUnids);
        elePorUnids.value = PorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleQParc = document.getElementById('inputQParc');
        let QParc = 5;
        eleQParc.value = QParc;
        let eleVParc = document.getElementById('inputVParc');
        let VParc = (VTotal / QParc);
        eleVParc.value = VParc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleParcPorUnids = document.getElementById('inputParcPorUnids');
        let ParcPorUnids = (VParc / TUnids);
        eleParcPorUnids.value = ParcPorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleTDas = document.getElementById('inputTDas');
        let TDas = (VTotal * 0.06);
        eleTDas.value = TDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleParcDas = document.getElementById('inputParcDas');
        let ParcDas = (TDas / QParc);
        eleParcDas.value = ParcDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleTPrc = document.getElementById('inputTPrc');
        let TPrc = (VTotal * 0.20);
        eleTPrc.value = TPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleParcPrc = document.getElementById('inputParcPrc');
        let ParcPrc = (TPrc / QParc);
        eleParcPrc.value = ParcPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        let eleLucro = document.getElementById('inputLucro');
        let Lucro = (VTotal - (TDas + TPrc));
        eleLucro.value = Lucro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        scrollTo(window, { top: 500 }).then(function () {
          // window has scrolled 500 pixels down the page
        });
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [controllerQuestion])
  //}, [controllerQuestion, viewQuestion])

  useEffect(() => {
    if (isFirstRun2.current) {
      isFirstRun2.current = false;
      return;
    }
    const { email, assunto, txtmsg } = sendMsgEmail
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('email', email);
      bodyFormData.set('assunto', assunto);
      bodyFormData.set('txtmsg', txtmsg);
      bodyFormData.set('apikey', '4326356426542564563465463445');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      //cl("sendEmail bodyFormData", bodyFormData);
      const response = await axios({
        method: 'post',

        url: 'https://jvfapp.ddns.net:3333/SendEmail',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("sendEmail", redata);

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [sendMsgEmail])

  useEffect(() => {
    if (isFirstRun7.current) {
      isFirstRun7.current = false;
      return;
    }
    const {
      printbol,
      cnpj,
      nomecond,
      end,
      end2,
      ndoc,
      nrdoc,
      nomeuni,
      cep,
      banco,
      ag,
      cc,
      vencto,
      valor2,
      dtlimite,
      multa,
      bloco,
      unid,
      endcob,
      endcob2,
      cepcob,
    } = GerBoleto;
    const cnpjformt = cnpj;
    //cl('cnpj',cnpj)
    //cl('cnpjformt',cnpjformt)
    //const { printbol } = GerBoleto;
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('cnpj', cnpjformt);
      bodyFormData.set('nomecond', nomecond);
      bodyFormData.set('end', end);
      bodyFormData.set('end2', end2);
      bodyFormData.set('unid', unid);
      bodyFormData.set('ndoc', ndoc);
      bodyFormData.set('nrdoc', nrdoc);
      bodyFormData.set('nomeuni', nomeuni);
      bodyFormData.set('cep', cep);
      bodyFormData.set('banco', banco);
      bodyFormData.set('ag', ag);
      bodyFormData.set('cc', cc);
      bodyFormData.set('vencto', vencto);
      bodyFormData.set('valor2', valor2);
      bodyFormData.set('dtlimite', dtlimite);
      bodyFormData.set('multa', multa);
      bodyFormData.set('bloco', bloco);
      bodyFormData.set('endcob', endcob);
      bodyFormData.set('endcob2', endcob2);
      bodyFormData.set('cepcob', cepcob);
      bodyFormData.set('chave', '3987MS0SJF09SFMG966505786V9UVN83CFNJH');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      //cl("sendEmail bodyFormData", bodyFormData);
      const responseBoleto = await axios({
        method: 'post',

        url: 'https://riluk.com.br/cb/341.php?apikey=3987MS0SJF09SFMG966505786V9UVN83CFNJH',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (responseBoleto) {
        //cl('GERBOLETO',responseBoleto['data']);
        let elepanelbol = document.getElementById('panelBoleto');
        elepanelbol.style.display = '';

        let elelabelbol = document.getElementById('labelboleto');
        elelabelbol.innerHTML = responseBoleto['data'];

        if (printbol == '1') { PHE.printElement(document.getElementById('labelboleto')); }
      };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("sendEmail", redata);

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [GerBoleto])





  async function gerarboleto(print, id) {

    setGerBoleto(
      {
        'cnpj': rep[id]['cgc'],
        'nomecond': rep[id]['nomecond'],
        'end': rep[id]['end'],
        'end2': rep[id]['end2'],
        'ndoc': rep[id]['ndoc'],
        'nrdoc': rep[id]['nrdoc'],
        'nomeuni': rep[id]['nomeuni'],
        'cep': rep[id]['cep'],
        'banco': rep[id]['banco'],
        'ag': rep[id]['ag'],
        'cc': rep[id]['cc'],
        'vencto': rep[id]['vencto'],
        'valor2': rep[id]['valor2'],
        'dtlimite': rep[id]['dtlimite'],
        'multa': rep[id]['multa'],
        'bloco': rep[id]['bloco'],
        'unid': rep[id]['unid'],
        'endcob': rep[id]['endcob'],
        'endcob2': rep[id]['endcob2'],
        'cepcob': rep[id]['cepcob'],
        'printbol': print
        // 'email': document.getElementById('inputEmail6').value,
        // 'txtmsg': document.getElementById('txtEmail').value,
      })

    //   var bodyFormData = new FormData();
    //   bodyFormData.set('chave', '3987MS0SJF09SFMG966505786V9UVN83CFNJH');
    //   bodyFormData.set('doc',global.gNome);
    //   //bodyFormData.set('password', password);
    //   //bodyFormData.append('password', 'senha');
    //   const responset = await axios({
    //     method: 'post',

    //     url: 'https://riluk.com.br/cb/341.php?apikey=3987MS0SJF09SFMG966505786V9UVN83CFNJH',
    //     //url: 'https://jvfapp.ddns.net:3333/listNewContacs',
    //     // url: 'https://192.168.99.250:3333/listNewContacs',

    //     //port: 3000,
    //     data: bodyFormData,
    //     config: { headers: { 'Content-Type': 'multipart/form-data' } }
    //   })

    // const resowwwt = responset;
    // cl('resowww',resowwwt);
    //   const linkurl = "https://riluk.com.br/cb/341.php?apikey=3987MS0SJF09SFMG966505786V9UVN83CFNJH";
    // //window.open(, '_blank');    


  }

  useEffect(() => {

    global.linkQuest = linkQuest;


  }, [linkQuest])

  //add050523

  useEffect(() => {


    async function fetchdata() {
      if (global.gaListCondDistinct.length > 1) {

        let eleCardbody = document.getElementById('cardGridBggBody');
        if (eleCardbody) { eleCardbody.style.display = 'none'; }
        let eselectConddiv = document.getElementById('selectConddiv');
        if (eselectConddiv) {

          let arrFound = gaListCondDistinct.filter(function (item, num) {
            return item.id === Number(eselectConddiv.value);
          });

        //cl("clgaListCondDistinct", gaListCondDistinct);
        //cl("clarrFound", arrFound);

          global.gCond = arrFound[0].cond;

          listViewPdf();


        }

      } else {
        return
      }
    }
    fetchdata();


  }, [SelGCond])
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    //cl("useEffects New Contact", AddNCtc)
    let ignore = false;
    const { inputNome, inputCid, inputEmail, inputFone, inputObs } = AddNCtc;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('frmnome', inputNome);
      bodyFormData.set('email', inputEmail);
      bodyFormData.set('cidadestado', inputCid);
      bodyFormData.set('telefone', inputFone);
      bodyFormData.set('mensagem', inputObs);
      bodyFormData.set('origem', 'APP_ADD');
      bodyFormData.set('progress', '10');
      bodyFormData.set('chave', global.makeChave(30));
      bodyFormData.set('resp', 'false');
      bodyFormData.set('apikey', '4326356426542564563465463445');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: 'https://admriluk/newcontacts.php?apikey=398738497834758934759834758934',
        //url: 'https://jvfapp.ddns.net:3333/newcontacts',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        setAtu({ '0': '0' });
        //cl('setatu 0 0')
        global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddNCtc])


  //AddNProp
  useEffect(() => {
    if (isFirstRun4.current) {
      isFirstRun4.current = false;
      return;
    }
    //cl("useEffects New Contact", AddNCtc)
    //cl('entrou AddNProp')
    //cl('entrou AddNProp', AddNProp);
    let ignore = false;
    const { chave,
      dtIn,
      dtAte,
      pags,
      qtdTorres,
      qtdUniRes,
      qtdUniCom,
      qtdDias,
      vlr_unit,
      vlr_total,
      parc,
      vlr_parc,
      vlr_unitotal,
      vlr_uniparc,
      numprop,
      tipo,
      div30,
      RetEntDocs } = AddNProp;
    //cl('depois const AddNProp', AddNProp);
    async function fetchdata() {
      var bodyFormData2 = new FormData();
      bodyFormData2.set('chave', chave);
      bodyFormData2.set('dtIn', dtIn);
      bodyFormData2.set('dtAte', dtAte);
      bodyFormData2.set('pags', pags);
      bodyFormData2.set('qtdTorres', qtdTorres);
      bodyFormData2.set('qtdUniRes', qtdUniRes);
      bodyFormData2.set('qtdUniCom', qtdUniCom);
      bodyFormData2.set('qtdDias', qtdDias);
      bodyFormData2.set('vlr_unit', vlr_unit);
      bodyFormData2.set('vlr_total', vlr_total);
      bodyFormData2.set('parc', parc);
      bodyFormData2.set('vlr_parc', vlr_parc);
      bodyFormData2.set('vlr_unitotal', vlr_unitotal);
      bodyFormData2.set('vlr_uniparc', vlr_uniparc);
      bodyFormData2.set('numprop', numprop);
      bodyFormData2.set('tipo', tipo);
      bodyFormData2.set('div30', div30);
      bodyFormData2.set('RetEntDocs', RetEntDocs);
      const response = await axios({
        method: 'post',

        url: 'https://admriluk/rest.php?apikey=398738497834758934759834758934',
        data: bodyFormData2,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { /*cl(response)*/ };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        ListarProp();
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        //setAtu({'0':'0'});
        //cl('setatu 0 0')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddNProp])

  //ListProp
  useEffect(() => {
    if (isFirstRun5.current) {
      isFirstRun5.current = false;
      return;
    }
    //cl("useEffects New Contact", AddNCtc)
    let ignore = false;
    const {
      chave,
      // numprop,
      // dtIn,
      // dtAte,
      // pags,
      // qtdTorres,
      // qtdUniRes,
      // qtdUniCom,
      // qtdDias,
      // vlr_unit,
      // vlr_total,
      // parc,
      // vlr_parc,
      // vlr_unitotal,
      // vlr_uniparc,
      // tipo,
      // div30
    } = ListProp;
    // cl('depois const AddNProp', AddNProp);
    async function fetchdata() {
      const linkurl = 'https://admriluk/restprop.php?apikey=398738497834758934759834758934&chave=' + global.chaveQuest + '&metodo=all';
      var bodyFormData2 = new FormData();
      bodyFormData2.set('chave', chave);
      const response = await axios({
        method: 'post',

        url: linkurl,
        data: bodyFormData2,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) {

      };

      if (!ignore) {
        const respprop = response['data'];
        var element = document.getElementById("divpage4");
        //limpar div
        element.innerHTML = "";
      //cl("respprop", respprop);
        if (respprop) {
          respprop.sort();
          respprop.reverse();
          setreprop(respprop);
          // respprop.forEach(function (item) {
          //   //cl('item',item);
          //   const para = document.createElement("a");
          //   const parabr = document.createElement("p");
          //   const paraI = document.createElement("i");
          //   paraI.setAttribute('class', 'far fa-file-word');
          //   const txtp = ' => ' + item['tipo'] + ' N° ' + item['prop'] + ' - Total ' + item['vlr_total'] + ' - ' + item['parc'] + ' parcelas de ' + item['vlr_parc'] + ' - Prazo ' + item['qtdDias'] + ' dias  - '
          //   const node = document.createTextNode(txtp);
          //   const linkurl = "https://admriluk/docx.php?apikey=398738497834758934759834758934&chave=" + item['chave'] + "&prop=" + item['prop'];
          //   para.setAttribute('href', linkurl);
          //   para.setAttribute('class', 'abgg');
          //   para.target = "_blank";
          //   parabr.appendChild(paraI);
          //   parabr.appendChild(node);


          //   // const para2 = document.createElement("a");
          //   // const paraI2 = document.createElement("i");
          //   // paraI2.setAttribute('class', 'fa fa-trash-o');
          //   // const linkurl2 = "confirmDelProp("+ item['prop']+',' + item['chave'] + "); return false;";
          //   // para2.setAttribute('href', '#');
          //   // para2.setAttribute('onClick', linkurl2);
          //   // para2.appendChild(paraI2);
          //   // parabr.appendChild(para2);

          //   para.appendChild(parabr);
          //   element.appendChild(para);
          // });
          let eleLoading = document.getElementById('divLoad');
          if (eleLoading) { eleLoading.style.display = 'none'; }
          scrollTo(window, { top: 500 }).then(function () {
            // window has scrolled 500 pixels down the page
          });
        } else {
          alert('Não possui propostas gerada !');
          scrollTo(window, { top: 500 }).then(function () {
            // window has scrolled 500 pixels down the page
          });
          ClosepanelProps();
        }
      }
    }
    fetchdata();
    setAtu({ '0': '0' });
    return () => { ignore = true };
  }, [ListProp])


  useEffect(() => {
    if (isFirstRun3.current) {
      isFirstRun3.current = false;
      //cl("DelNCtc first3 false")
      return;
    }
    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    const { chavedel } = DelNCtc;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('chavedel', chavedel);
      bodyFormData.set('apikey', '4326356426542564563465463445');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',
        url: 'https://admriluk/delcontact.php?apikey=398738497834758934759834758934',
        //url: 'https://jvfapp.ddns.net:3333/deleteContacs',
        data: bodyFormData,
        responseType: 'blob',
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { };

      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        setAtu({ '01': '01' });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [DelNCtc])

  //setDelProp
  useEffect(() => {
    if (isFirstRun6.current) {
      isFirstRun6.current = false;
      //cl("DelNCtc first3 false")
      return;
    }
    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    const { chavedel, propdel } = DelProp;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('chavedel', chavedel);
      bodyFormData.set('propdel', propdel);
      //bodyFormData.set('apikey', '4326356426542564563465463445');
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        url: 'https://admriluk/delprop.php?apikey=398738497834758934759834758934',

        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response) { };
      // async function fetchdata() {
      //   const resp = await fetch('https://jvfcondominial.com.br/app/api/contacts/readnext.php?id=1&apikey=398738497834758934759834758934')
      //   const data = await resp.json();
      //   //cl(data);
      //   if (!ignore) setrep(data['records']);
      // }
      //fetchdata();
      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        setListProp({ 'chave': chavedel });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [DelProp])



  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [reprop])


  //Funções

  global.formAddNewContactReset = function (params) {
    document.getElementById("formAddNewContact").reset();
    const idcampos = [
      'inputNome',
      'inputCid',
      'inputEmail4',
      'inputFone',
      'inputObs'
    ]
    for (var i = 0; i < idcampos.length; i++) {
      let eNnome = document.getElementById(idcampos[i])
      eNnome.classList.remove("is-valid")
      eNnome.classList.remove("is-invalid")
    }
  }


  function addNewContact(params) {
    const eNnome = document.getElementById('btnGravar')
    if (!eNnome.classList.contains("disabled")) {

      //eNnome.removeAttribute("onclick");
      if (!global.inpAddFrmC.inputNome) { return validaNome(1) };
      if (!global.inpAddFrmC.inputEmail4) { return validaEmail4(1, 'inputEmail4') };
      if (!global.inpAddFrmC.inputCid) { return validaCid(1) };
      if (!global.inpAddFrmC.inputFone) { return validaFone(1) };
      if (!global.inpAddFrmC.inputObs) { return validaObs(1) };

      //reset form
      //formAddNewContactReset()

      if (
        global.inpAddFrmC.inputCid &&
        global.inpAddFrmC.inputEmail4 &&
        global.inpAddFrmC.inputFone &&
        global.inpAddFrmC.inputNome &&
        global.inpAddFrmC.inputObs
      ) {
        //cl("gravar")
        setAddNCtc(
          {
            'inputNome': document.getElementById('inputNome').value,
            'inputEmail': document.getElementById('inputEmail4').value,
            'inputCid': document.getElementById('inputCid').value,
            'inputFone': document.getElementById('inputFone').value,
            'inputObs': document.getElementById('inputObs').value
          }

        );
        eNnome.classList.add("disabled");
        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = ''; }
      }
      const eActions = document.getElementById('panelActions')
      eActions.style.display = 'none';
    } else {
      //cl("block")
    }



  }
  function bViewPdf(xcond, xpasta, xfile) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );
    //cl("alterar")
    let eleIapikey = document.getElementById('iapikey');
    eleIapikey.value = "3987MS0SJF09SFMG966505786V9UVN83CFNJH"
    let eleIcond = document.getElementById('icond');
    eleIcond.value = xcond
    let eleIpasta = document.getElementById('ipasta');
    eleIpasta.value = xpasta
    let eleIarqv = document.getElementById('iarqv');
    eleIarqv.value = xfile
    let eleFvdocs = document.getElementById('formVdocs');
    eleFvdocs.submit();
    eleIapikey.value = ""
    eleIcond.value = ""
    eleIpasta.value = ""
    eleIarqv.value = ""


  }

  function listViewPdf(params) {
    setViewPdf(
      {
        'inputNome': '1',

      }

    );


  }

  function AddNewProp(params) {
    const eNnome = document.getElementById('panelProps')
    eNnome.style.display = '';
    scrollTo(window, { top: 500 }).then(function () {
      // window has scrolled 500 pixels down the page
    });
    var element = document.getElementById("divpage4");
    element.innerHTML = "";

    const propnumber = NumberProp();
    let propFinalidade = '';
    let eleTipoFinalidade = document.getElementsByName('txtTipo');

    for (var i = 0; i < eleTipoFinalidade.length; i++) {
      if (eleTipoFinalidade[i].checked) {
        propFinalidade = eleTipoFinalidade[i].value;
      }
    }

    let RetEnDocsV;
    if (document.getElementById('txtRetira').checked === true) {
      RetEnDocsV = 1;
    } else {
      RetEnDocsV = 0;
    }

    //const xpropnumber = propnumber.replace(/\/|-/g, '');;
  //cl('tipo radios checked', propFinalidade);
    setAddNProp(
      {
        'chave': global.chaveQuest,
        'dtIn': document.getElementById('inputDtIni').value,
        'dtAte': document.getElementById('inputDtFim').value,
        'pags': document.getElementById('inputTPags').value,
        'qtdTorres': global.QtdTorres,
        'qtdUniRes': global.QtdUniRes,
        'qtdUniCom': global.QtdUniCom,
        'qtdDias': document.getElementById('inputPrazo').value,
        'vlr_unit': realParaNumber(document.getElementById('inputVUnit').value),
        'vlr_total': realParaNumber(document.getElementById('inputVTotal').value),
        'parc': document.getElementById('inputQParc').value,
        'vlr_parc': realParaNumber(document.getElementById('inputVParc').value),
        'vlr_unitotal': realParaNumber(document.getElementById('inputPorUnids').value),
        'vlr_uniparc': realParaNumber(document.getElementById('inputParcPorUnids').value),
        'numprop': propnumber,
        'tipo': propFinalidade,
        'div30': document.getElementById('inputDiasMeses30').value,
        'RetEntDocs': RetEnDocsV
      }
    )

  }

  function ListarProp(params) {
    setListProp(
      {
        'chave': global.chaveQuest
      }
    )
    const eNnome = document.getElementById('panelProps')
    eNnome.style.display = '';
    const eNnomes = document.getElementById('spanSino')
    eNnomes.style.display = 'none';
    scrollTo(window, { top: 500 }).then(function () {
      // window has scrolled 500 pixels down the page
    });
    eNnome.focus();
    var element = document.getElementById("divpage4");
    element.innerHTML = "";
  }

  function PrintBoleto(params) {
    PHE.printElement(document.getElementById('labelboleto'));
  }

  function CloseaddNewContact(params) {
    const eNnome = document.getElementById('panelActions')
    global.formAddNewContactReset();
    eNnome.style.display = 'none';
  }
  function ClosepanelProps(params) {
    const eNnome = document.getElementById('panelProps')
    eNnome.style.display = 'none';
  }
  function ClosepanelBoleto(params) {
    const eNnome = document.getElementById('panelBoleto')
    eNnome.style.display = 'none';
  }
  function CloseadEnviarEmail(params) {
    const eNnome = document.getElementById('panelEmail')
    // global.formAddNewContactReset();
    eNnome.style.display = 'none';
    let eleCard = document.getElementById('cardGridBgg');
    eleCard.classList.remove("collapsed-card");
    let eleCardI = document.getElementById('cardGridBggI');
    eleCardI.classList.remove("fa-plus");
    eleCardI.classList.add("fa-minus");
    let eleCardbody = document.getElementById('cardGridBggBody');
    eleCardbody.style.display = '';
  }
  function CloseadQuest(params) {
    const eNnome = document.getElementById('panelQuest')
    // global.formAddNewContactReset();
    eNnome.style.display = 'none';
    let eleCard = document.getElementById('cardGridBgg');
    eleCard.classList.remove("collapsed-card");
    let eleCardI = document.getElementById('cardGridBggI');
    eleCardI.classList.remove("fa-plus");
    eleCardI.classList.add("fa-minus");
    let eleCardbody = document.getElementById('cardGridBggBody');
    eleCardbody.style.display = '';
    ClosepanelProps();

  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCid')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function gerContratoLink(params) {

    window.location.href = "#/contratos-gerenciar";


  }
  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }
  function validaAssunto(params) {
    const eNnome = document.getElementById('inputAssunto')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputAssunto = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    im.mask(eNnome);

  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }
  // function validaEmail5(params, idinput) {
  //   const eNemail = document.getElementById('inputEmail5')
  //   const email = eNemail.value;
  //   if (EmailValidator.validate(email)) {
  //     eNemail.classList.add("is-valid")
  //     eNemail.classList.remove("is-invalid")
  //     global.inpAddFrmC.inputEmail5 = true;
  //     //cl("true global valida", global.inpAddFrmC)
  //   } else {
  //     eNemail.classList.add("is-invalid")
  //     eNemail.classList.remove("is-valid")
  //     //cl("FALSE global valida", global.inpAddFrmC, params)
  //     if (params === 1) { return eNemail.focus() }
  //   }
  //   if (email) { eNemail.value = params.toLowerCase() };

  // }
  function validaEmail6(params, idinput) {
    const eNemail = document.getElementById('inputEmail6')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail6 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email && params !== 1) { eNemail.value = params.toLowerCase() };

  }

  // function formataCPF(cpf) {
  //   //retira os caracteres indesejados...
  //   cpf = cpf.replace(/[^\d]/g, "");

  //   //realizar a formatação...
  //   return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  // }

  function formataCnpj(Cnpj) {
    //retira os caracteres indesejados...
    Cnpj = Cnpj.replace(/[^\d]/g, "");

    //realizar a formatação...
    return Cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  // function sendEmailModal(params) {
  //   // var myModal = document.getElementById('modal01');
  //   // var myModalInstance = new Modal(myModal,
  //   //   { // options object
  //   //     content: '<div class="modal-body">Some content to be set on init</div>', // sets modal content
  //   //     backdrop: 'static', // we don't want to dismiss Modal when Modal or backdrop is the click event target
  //   //     keyboard: false // we don't want to dismiss Modal on pressing Esc key
  //   //   });

  //   // // OR initialize and show the modal right away
  //   // var myModalInstance = new Modal(myModal, options);
  //   // myModalInstance.show();
  //   // //cl("sendemail btn")



  // }

  function clicopied(params) {
    let ele = document.getElementById("txtLinkQuest")
    ele.textContent = "Link Copiado, basta colar..."

  }

  function calculaDias(date1, date2) {
    //formato do brasil 'pt-br'
    moment.locale('pt-br');
    //setando data1
    var data1 = moment(date1, 'DD/MM/YYYY');
    //setando data2
    var data2 = moment(date2, 'DD/MM/YYYY');
    //tirando a diferenca da data2 - data1 em dias
    var diff = data2.diff(data1, 'days');

    return diff;
  }

  function page1(params) {
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title">Adicionar - Novo Contato</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputNome">Nome</label>
                        <input type="text" className="form-control" onChange={e => validaNome(e.target.value)} id="inputNome" placeholder="Nome" />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="inputEmail4">Email</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">@</span>
                          <input type="email" className="form-control" onChange={e => validaEmail4(e.target.value, 'inputEmail4')} id="inputEmail4" placeholder="Email" />
                        </div>

                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="inputCity">Cidade/Estado</label>
                        <input type="text" className="form-control" onChange={e => validaCid(e.target.value)} placeholder="Ex: São Paulo - SP" id="inputCid" />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="inputFone">Fone</label>
                        {/* <input type="text" className="form-control" data-inputmask="&quot;mask&quot;: &quot;(999) 999-9999&quot;" data-mask im-insert="true" /> */}
                        <input type="text" className="form-control" onChange={e => validaFone(e.target.value)} placeholder="Ex: 11 99901-XXXX" id="inputFone" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputObs">Obs</label>
                      <input type="text" className="form-control" onChange={e => validaObs(e.target.value)} id="inputObs" placeholder="Obs" />
                    </div>
                    <button type="button" className="btn btn-info " id="btnGravar" onClick={addNewContact}>Gravar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={CloseaddNewContact}>Fechar</button>
                  </form>




                </div>

              </div>

              {/* <div className="card collapsed-card">
                <div className="card-header ui-sortable-handle" style={{ cursor: 'move' }}>
                  <h3 className="card-title">
                    <i className="ion ion-clipboard mr-1" />
                    To Do List
            </h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>

                  </div>
                </div>
                <div className="card-body">
                  <ul className="todo-list ui-sortable">
                    <li>
                      <span className="handle ui-sortable-handle">
                        <i className="fas fa-ellipsis-v" />
                        <i className="fas fa-ellipsis-v" />
                      </span>
                      <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" defaultValue name="todo1" id="todoCheck1" />
                        <label htmlFor="todoCheck1" />
                      </div>
                      <span className="text">Design a nice theme</span>
                      <small className="badge badge-danger"><i className="far fa-clock" /> 2 mins</small>
                      <div className="tools">
                        <i className="fas fa-edit" />
                        <i className="fas fa-trash-o" />
                      </div>
                    </li>
                    <li>
                      <span className="handle ui-sortable-handle">
                        <i className="fas fa-ellipsis-v" />
                        <i className="fas fa-ellipsis-v" />
                      </span>
                      <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" defaultValue name="todo2" id="todoCheck2" />
                        <label htmlFor="todoCheck2" />
                      </div>
                      <span className="text">Make the theme responsive</span>
                      <small className="badge badge-info"><i className="far fa-clock" /> 4 hours</small>
                      <div className="tools">
                        <i className="fas fa-edit" />
                        <i className="fas fa-trash-o" />
                      </div>
                    </li>
                    <li>
                      <span className="handle ui-sortable-handle">
                        <i className="fas fa-ellipsis-v" />
                        <i className="fas fa-ellipsis-v" />
                      </span>
                      <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" defaultValue name="todo3" id="todoCheck3" />
                        <label htmlFor="todoCheck3" />
                      </div>
                      <span className="text">Let theme shine like a star</span>
                      <small className="badge badge-warning"><i className="far fa-clock" /> 1 day</small>
                      <div className="tools">
                        <i className="fas fa-edit" />
                        <i className="fas fa-trash-o" />
                      </div>
                    </li>
                    <li>
                      <span className="handle ui-sortable-handle">
                        <i className="fas fa-ellipsis-v" />
                        <i className="fas fa-ellipsis-v" />
                      </span>
                      <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" defaultValue name="todo4" id="todoCheck4" />
                        <label htmlFor="todoCheck4" />
                      </div>
                      <span className="text">Let theme shine like a star</span>
                      <small className="badge badge-success"><i className="far fa-clock" /> 3 days</small>
                      <div className="tools">
                        <i className="fas fa-edit" />
                        <i className="fas fa-trash-o" />
                      </div>
                    </li>
                    <li>
                      <span className="handle ui-sortable-handle">
                        <i className="fas fa-ellipsis-v" />
                        <i className="fas fa-ellipsis-v" />
                      </span>
                      <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" defaultValue name="todo5" id="todoCheck5" />
                        <label htmlFor="todoCheck5" />
                      </div>
                      <span className="text">Check your messages and notifications</span>
                      <small className="badge badge-primary"><i className="far fa-clock" /> 1 week</small>
                      <div className="tools">
                        <i className="fas fa-edit" />
                        <i className="fas fa-trash-o" />
                      </div>
                    </li>
                    <li>
                      <span className="handle ui-sortable-handle">
                        <i className="fas fa-ellipsis-v" />
                        <i className="fas fa-ellipsis-v" />
                      </span>
                      <div className="icheck-primary d-inline ml-2">
                        <input type="checkbox" defaultValue name="todo6" id="todoCheck6" />
                        <label htmlFor="todoCheck6" />
                      </div>
                      <span className="text">Let theme shine like a star</span>
                      <small className="badge badge-secondary"><i className="far fa-clock" /> 1 month</small>
                      <div className="tools">
                        <i className="fas fa-edit" />
                        <i className="fas fa-trash-o" />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer clearfix">
                  <button type="button" className="btn btn-info float-right"><i className="fas fa-plus" /> Add item</button>
                </div>
              </div>*/}
            </section>

          </div>

        </section>
      </div>

    )

  }

  function page2(params) {
    const vassuntoMsg = "Auditoria Condominial - Solicitação de informações - " + dateFormat(Date.now(), "dd/mm/yy");
    return (

      <div id="panelEmail" name="panelEmail" style={{ position: 'relative', display: 'none' }}>

        <div className="modal" tabIndex={-1} role="dialog" id="modal01">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enviar Email em desenvolvimento</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Status do envio de email : em desenvolvimeto</p>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="headCEmail">Enviar Email</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      {/*<div className="form-group col-md-6">
                        <label htmlFor="labelSelect">Selecione o Modelo do email : </label>
                         <select className="form-control select2 select2-hidden-accessible" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                          <option selected="selected" data-select2-id={3}>Link para questionário Incial</option>
                          {/* <option data-select2-id={29}>Cotação Inicial</option> 
                        </select> 
                      </div>*/}
                      <div className="form-group col-md-6">
                        <label htmlFor="labelEmail5">Email</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">@</span>
                          <input type="email" className="form-control" onChange={e => validaEmail6(e.target.value, 'inputEmail6')} id="inputEmail6" placeholder="Email" />
                        </div>

                      </div>
                      <div className="form-group col-md-12">
                        <label htmlFor="labelAssunto">Assunto</label>
                        <input type="text" className="form-control" value={vassuntoMsg} onChange={e => validaAssunto(e.target.value, 'labelEmail5')} id="inputAssunto" placeholder="Assunto" />
                      </div>
                    </div>
                    {/* <button type="button" id="btnAcessarLink" name="btnAcessarLink" formtarget="_blank" target={linkQuest} className="btn btn-info btn-sm float-right" > Acessar</button> */}
                    <a href={linkQuest} target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-right"><i className='fa fa-external-link'></i></a>
                    <CopyToClipboard id="" text={linkQuest} onCopy={() => clicopied()} >
                      <button type="button" id="copyclip" name="copyclip" className="btn btn-info btn-sm float-right" > Copy Link</button>
                    </CopyToClipboard>
                    <span id="txtLinkQuest" className="input-group-text float-right" style={{ fontSize: 12 }}>
                      {linkQuest}</span>
                    <div className="form-group">
                      <label htmlFor="inputObs">Mensagem</label>
                      {/* <input type="text" className="form-control" onChange={e => validaObs(e.target.value)} id="inputObs" placeholder="Obs" /> */}
                      <textarea className="form-control" id="txtEmail" rows={3} placeholder="Enter ..." defaultValue={""} />

                    </div>


                    <button type="button" className="btn btn-info " id="btnEnviar" onClick={enviarEmail}>Enviar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={CloseadEnviarEmail}>Fechar</button>
                  </form>




                </div>

              </div>

            </section>

          </div>

        </section>
      </div>

    )

  }
  function page3(params) {
    //const vassuntoMsg = "Auditoria Condominial - Solicitação de informações - " + dateFormat(Date.now(), "dd/mm/yy");
    return (

      <div id="panelQuest" name="panelQuest" style={{ position: 'relative', display: 'none' }}>

        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="headCQuestion">Questionário Respondido</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formRespQuestion">
                    <div className="form-row">
                      <div className="col-md-6">
                        <p id="labeltest" name="labeltest"><img id="imgloading" width="50px" alt="Loading.." src={Loading}></img></p>
                        {/* <button type="button" className="btn btn-info float-left" id="btnGerarProp" name="btnGerarProp" onClick={e => testeModal("Em breve ...")}>Teste Modal</button> */}
                      </div>
                      <div className="col-md-6 row" id="divcalcular" name="divcalcular">
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Pré Cálculo para Proposta - Tipo de Proposta</label>
                        </div>
                        <div className="row col-md-12">
                          <br></br>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <input type="radio" id="txtTipoI" name="txtTipo" value="Auditoria Investigativa" /><label id="labelTipoI" className="labelBgg"> Auditoria Investigativa</label>
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <input type="radio" id="txtTipoP" name="txtTipo" value="Auditoria Preventiva" /><label id="labelTipoP" className="labelBgg"> Auditoria Preventiva</label>
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <input type="radio" id="txtTipoG" name="txtTipo" value="Auditoria de Gestão" /><label id="labelTipoG" className="labelBgg">Auditoria de Gestão</label>
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <input type="checkbox" name="txtRetira" id="txtRetira" onClick={e => bggcheck()} /><label id="labelRetira" className="labelBgg">Inibir Ret./Entr. Docs.</label>
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Período à Calcular</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Início</label>
                              <input type="date" className="form-control" id="inputDtIni" placeholder="Inicial" onBlur={e => CalcSimule()} />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Final</label>
                              <input type="date" className="form-control" id="inputDtFim" placeholder="Final" onBlur={e => CalcSimule()} />
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Base de Cálculo (Totais)</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Dias</label>
                              <input type="text" disabled className="form-control" id="inputDifDias" placeholder="Dias" />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Div30</label>
                              <input type="text" disabled className="form-control" id="inputDiasMeses30" placeholder="Meses" />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Pags.</label>
                              <input type="number" step="1" className="form-control" id="inputTPags" placeholder="Paginas" />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Unids.</label>
                              <input type="number" step="1" className="form-control" id="inputTUnids" onChange={e => CalcSimule()} placeholder="Unidades" />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Dias Pasta</label>
                              <input type="number" step="0.01" className="form-control" id="inputTDiasPasta" onChange={e => CalcSimule()} placeholder="" />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Prazo</label>
                              <input type="text" className="form-control" onChange={e => CalcDias()} id="inputPrazo" placeholder="" />
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Valores em R$</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Unitário</label>
                              <input type="text" className="form-control" id="inputVUnit" onBlur={e => CalcSimule()} placeholder="Valor Unitário" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Total</label>
                              <input type="text" disabled className="form-control" id="inputVTotal" placeholder="Valor Total" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Por Unidade</label>
                              <input type="text" disabled className="form-control" id="inputPorUnids" placeholder="Valor Por unidades" />
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Forma de Pagamento</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm-2">
                            <div className="form-group ">
                              <label >Parcelas</label>
                              <input type="number" step="1" className="form-control" id="inputQParc" onChange={e => CalcSimule()} placeholder="Parcelas" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Valor Parcela</label>
                              <input type="text" disabled className="form-control" id="inputVParc" placeholder="Valor Parcela" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Por Unidade</label>
                              <input type="text" disabled className="form-control" id="inputParcPorUnids" placeholder="Valor Por unidades" />
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">DAS(6%)</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >DAS Total</label>
                              <input type="text" disabled className="form-control" id="inputTDas" placeholder="Das Total" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >DAS Parc.</label>
                              <input type="text" disabled className="form-control" id="inputParcDas" placeholder="Valor Parcela" />
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Parceiro(20%)</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Parceiro Total</label>
                              <input type="text" disabled className="form-control" id="inputTPrc" placeholder="" />
                            </div>
                          </div>
                          <div className="col-sm">
                            <div className="form-group ">
                              <label >Parceiro Parc.</label>
                              <input type="text" disabled className="form-control" id="inputParcPrc" placeholder="" />
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12">
                          <label id="labelTCalc" name="labelTCalc">Lucratividade</label>
                        </div>
                        <div className="row col-md-12">
                          <div className="col-sm-8">
                            <div className="form-group ">
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group ">
                              <label >Líquido</label>
                              <input type="text" disabled className="form-control" id="inputLucro" placeholder="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row col-12">
                      <div className="col-sm-7">
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-info float-left" id="btnGerarProp" name="btnGerarProp" onClick={e => AddNewProp()}>Criar Proposta</button>
                      </div>
                      <div className="col-sm-2">
                        <button type="button" className="btn btn-info float-left" id="btnListarProp" name="btnListarProp" onClick={e => ListarProp()}>Listar Propostas</button>
                      </div>
                      {/* <div className="col-sm-2">
                        <button type="button" className="btn btn-info float-left" id="btnGerarProp" name="btnGerarProp" onClick={e => InitProp("Em breve ...")}>Iniciar Proposta</button>
                      </div> */}
                      <div className="col-sm-1">
                        <button type="button" className="btn btn-info float-right" id="btnCloseQuest" name="btnCloseQuest" onClick={CloseadQuest}>Fechar</button>
                      </div>
                    </div>
                  </form>




                </div>

              </div>

            </section>

          </div>

        </section>
      </div >

    )

  }

  const ref = null;

  // function testeModal(params) {
  function page4(params) {
    return (
      <div id="panelProps" name="panelProps" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title">Propostas - Selecione abaixo para gerar arquivo...</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formpanelProps">
                    <div className="form-row" >
                      <div id="divpage4" className="form-group col-md-12">

                      </div>
                    </div>
                    <ReactTabulator
                      ref={(ref)}
                      columns={columnsProp}
                      data={reprop}
                      rowClick={rowClick}
                      cellClick={rowClick}
                      options={options}
                      data-custom-attr="test-custom-attribute"
                      className="#example-table-theme"
                    />
                    <br />
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={ClosepanelProps}>Fechar</button>
                  </form>
                </div>
              </div>

            </section>

          </div>

        </section>
      </div>

    )

  }
  function page5(params) {
    return (
      <div id="panelBoleto" name="panelBoleto" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title">Boleto</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formpanelProps">
                    <div className="form-row" >
                      <div id="divpage4" className="form-group col-md-12">
                        <p id="labelboleto" name="labelboleto" />
                      </div>
                    </div>

                    <br />
                    <button type="button" className="btn btn-info float-left" id="btnPrintBoleto" name="btnPrintBoleto" onClick={e => PrintBoleto()}>Imprimir</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={ClosepanelBoleto}>Fechar</button>
                  </form>
                </div>
              </div>

            </section>

          </div>

        </section>
      </div>

    )

  }

  function bggcheck(params) {
    const ele = document.getElementById('txtRetira')
    if (ele.checked === true) {
      ele.value = "1";
    } else {
      ele.value = "0";
    }
    //const result = ele.value;
    //console.log('checkbox', result) ;

  }

  function confirmDel(nome, chave) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este registro ?</h1><br></br><br></br>
            <p id='pmodal'><b>{nome}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                setDelNCtc(
                  {
                    'chavedel': chave
                  });
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function confirmDelProp(num, tipo, total, chave) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar esta proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{num} - {tipo} - Valor {total}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                setDelProp(
                  {
                    'chavedel': chave,
                    'propdel': num
                  });
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function removeOptions(selectElement) {
    if (!selectElement) {
      return
    }

    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  function abreviaNomeCond(params) {

    params = params.replace("CONDOMÍNIO", "COND.");
    params = params.replace("EDIFÍCIO", "ED.");
    params = params.replace("CONDOMINIO", "COND.");
    params = params.replace("EDIFICIO", "ED.");
    params = params.replace("RESIDENCIAL", "RES.");
    params = params.replace("ADMINISTRAÇÃO", "ADM.");

    return params

  }

  function modalBase(h1, p) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>{h1}</h1><br></br><br></br>
            <p id='pmodal'><b>{p}</b></p>
            <br></br>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button
              className="btn bg-gradient-info btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div>
        );
      }
    });
  }

  // }

  // function InitProp(params) {
  //   //alert(params);
  //   //let msg = '';
  //   // let doc = new jsPDF({
  //   //   orientation: 'p',
  //   //   unit: 'mm',
  //   //   format: 'a4',
  //   //   putOnlyUsedFonts:true
  //   // });
  //   // // doc.text('Hello world!', 10, 10);
  //   // // doc.save('a4.pdf');

  //   // doc.addHTML(msg);
  //   // doc.save('a4.pdf');
  //   var doc = new jsPDF({
  //     orientation: 'p',
  //     unit: 'mm',
  //     format: 'a4',
  //     putOnlyUsedFonts: true,
  //     compress: true,
  //   });
  //   doc.setFont("Arial");
  //   doc.setFontStyle("normal");
  //   let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  //   let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  //   //let str = "Your footer text";
  //   doc.setDrawColor(84, 141, 212);
  //   doc.rect(8, 8, 194, 281);

  //   let img = new Image();
  //   //var path = require('path');
  //   //img.src = path.resolve('apple-icon.png');
  //   img.src = Logo;
  //   doc.addImage(img, 'PNG', (pageWidth / 2) - 16.54, 15, 33.08, 26.5);
  //   doc.setFontSize(15);
  //   doc.line(20, 45, 190, 45);
  //   doc.setFontStyle('Italic');
  //   let titulo = 'PROPOSTA DE AUDITORIA CONDOMINIAL – Nº ' + NumberProp();
  //   doc.text(titulo, pageWidth / 2, 55, 'center');
  //   doc.setFontSize(11);
  //   doc.setFontStyle('bold');
  //   doc.text('FINANCEIRA – OPERACIONAL – TRIBUTÁRIA – OBRIGAÇÕES LEGAIS', pageWidth / 2, 65, 'center');
  //   doc.setFontStyle('normal');
  //   //footer
  //   doc.setTextColor(84, 141, 212);
  //   doc.text('dreyfusvieira.com.br', 10, pageHeight - 10, 'left');
  //   doc.text(dateFormat(Date.now(), "dd/mm/yyyy"), pageWidth - 10, pageHeight - 10, 'right');
  //   doc.addPage('a4', '1');
  //   doc.rect(8, 8, 194, 281);
  //   //doc.fromHTML(msg);
  //   doc.output('dataurlnewwindow');
  //   //doc.save('a4.pdf');

  // }

  function NumberProp(params) {

    let idprop = dateFormat(Date.now(), "ddmmHHMMss/yy");
    return idprop;

  }

  // function numberParaReal(numero) {
  //   var formatado = "R$ " + numero.toFixed(2).replace('.', ',');
  //   return formatado;
  // }

  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  // function TeclaEnter(params) {

  // }


  function CalcSimule(params) {

    let eleDtIni = document.getElementById('inputDtIni');
    let eleDtFim = document.getElementById('inputDtFim');

    let eleDifDias = document.getElementById('inputDifDias');
    let difDias = calculaDias(dateFormat(eleDtIni.value, "dd/mm/yyyy"), dateFormat(eleDtFim.value, "dd/mm/yyyy"));
    eleDifDias.value = difDias;
    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let M30 = Math.floor(difDias / 30);
    eleMeses30.value = M30;

    let eleTUnids = document.getElementById('inputTUnids');
    let TUnids = eleTUnids.value;

    let eleTDiasPasta = document.getElementById('inputTDiasPasta');
    let TDiasPasta = eleTDiasPasta.value;
    let elePrazo = document.getElementById('inputPrazo');
    let Prazo = (TDiasPasta * M30);
    Prazo = Math.round(Prazo);
    elePrazo.value = Prazo;

    let eleVUnit = document.getElementById('inputVUnit');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleVTotal = document.getElementById('inputVTotal');
    let VTotal = (M30 * Vunit);
    eleVTotal.value = VTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let elePorUnids = document.getElementById('inputPorUnids');
    let PorUnids = (VTotal / TUnids);
    elePorUnids.value = PorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleQParc = document.getElementById('inputQParc');
    let QParc = eleQParc.value;
    let eleVParc = document.getElementById('inputVParc');
    let VParc = (VTotal / QParc);
    eleVParc.value = VParc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPorUnids = document.getElementById('inputParcPorUnids');
    let ParcPorUnids = (VParc / TUnids);
    eleParcPorUnids.value = ParcPorUnids.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    let eleTDas = document.getElementById('inputTDas');
    let TDas = (VTotal * 0.06);
    eleTDas.value = TDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcDas = document.getElementById('inputParcDas');
    let ParcDas = (TDas / QParc);
    eleParcDas.value = ParcDas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleTPrc = document.getElementById('inputTPrc');
    let TPrc = (VTotal * 0.20);
    eleTPrc.value = TPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleParcPrc = document.getElementById('inputParcPrc');
    let ParcPrc = (TPrc / QParc);
    eleParcPrc.value = ParcPrc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    let eleLucro = document.getElementById('inputLucro');
    let Lucro = (VTotal - (TDas + TPrc));
    eleLucro.value = Lucro.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });


  }

  function CalcDias(params) {

    let eleMeses30 = document.getElementById('inputDiasMeses30');
    let elePrazo = document.getElementById('inputPrazo');
    let eleTDias = document.getElementById('inputTDiasPasta');
    eleTDias.value = (elePrazo.value / eleMeses30.value);



  }

  function rowClick(e, row) {
    ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
    //cl("rowClick id: " + row.getData().id, row, e);
    ////cl(rep);

  };

  function novoContato(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = '';

  }

  function enviarEmail(assunto, email, txtmsg) {
    validaEmail6(document.getElementById('inputEmail6').value, 'inputEmail6')
    if (!global.inpAddFrmC.inputEmail6) { return validaEmail6(1, 'inputEmail6') }
    //cl("enviar email global.inpAddFrmC.inputEmail6 > ", global.inpAddFrmC.inputEmail6)
    //cl("enviar email global.inpAddFrmC > ", global.inpAddFrmC)
    if (global.inpAddFrmC.inputEmail6) {
      setsendMsgEmail(
        {
          'assunto': document.getElementById('inputAssunto').value,
          'email': document.getElementById('inputEmail6').value,
          'txtmsg': document.getElementById('txtEmail').value,
        }

      );
      let ele = document.getElementById('panelEmail');
      ele.style.display = 'none';
      let eleCard = document.getElementById('cardGridBgg');
      eleCard.classList.remove("collapsed-card");
      let eleCardI = document.getElementById('cardGridBggI');
      eleCardI.classList.remove("fa-plus");
      eleCardI.classList.add("fa-minus");
      let eleCardbody = document.getElementById('cardGridBggBody');
      eleCardbody.style.display = '';

      //cl("enviou email")
    }
  }

  function dataTable(params) {

    const iframeStyle = {
      margin: "0 auto",
      maxWidth: "100%",
      width: "100%",
      height: "300px",
      border: "1px solid #cfcfcf"
    };


    return (
      <>
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div className="card-header ui-sortable-handle gertitle locCont" >
            <h3 className="card-title locCont">Boletos dentro do prazo Bancário</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoContato} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBody">
            <ReactTabulator
              ref={(ref)}
              columns={columns}
              data={rep}
              rowClick={rowClick}
              cellClick={rowClick}
              options={options}
              data-custom-attr="test-custom-attribute"
              className="#example-table-theme"
            />
            {/* <div className="divLineBar">
        <img id="imgLineBar" width="auto10%" alt="Loading.." src={LineBar}></img>
        </div> */}
            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
            ** boletos fora do prazo bancário, favor entrar em contato com administradora.


            {/* <iframe title="embed-test" style={iframeStyle} src={riddleUrl}></iframe> */}
          </div>
        </div>
      </>
    )

  }
  function ViewDocs(params) {
    let linkaction = global.gLinkBase + '/vdocs_dw.php';
    //{linkaction}

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardSelCond" >
          <div id="divLoad" className="load" style={{ display: '' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          <div className="card-body locCont" id="cardSelCondBody">

            <section className="content-header">
              <h1 className="box-title-bgg">Condomínio selecionado</h1>
              <section className="content" id="divSelCond5">

                <select id="selectConddiv" onChange={() => validaSelectCond()}>
                </select>
              </section>
            </section>
          </div>

        </div>
        <div className="card card-info cardGridBgg" id="cardGridBgg" >
          <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          <div className="card-body locCont" id="cardGridBggBody">
            <form id="formVdocs" action={linkaction} method="post" >
              <input id="iapikey" type="hidden" name="apikey" value="" />
              <input id="icond" type="hidden" name="cond" value="" />
              <input id="ipasta" type="hidden" name="pasta" value="" />
              <input id="iarqv" type="hidden" name="arqv" value=".pdf" />
              {/* <input id="bSubmit" type="submit" value="submit" formtarget="_blank"/> */}
              {/* <button type="button" className="btn btn-info " id="btnGravar" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>PDF</button>
              <a target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-sm float-left" onClick={e => bViewPdf('5014', 'ATAS', 'AGE_2018_ 02_20.PDF')}>Link</a> */}
              <section className="content-header">
                <h1 id="TxtSeltxt" className="box-title-bgg">Selecione o Regulamento abaixo para realizar o download (pdf)</h1>

                <section className="content" id="divpage5">

                </section>
              </section>
            </form>
          </div>

        </div>
      </>

    )
  }

  function nomeAta(nome) {
    nome = nome.replace('.pdf', '');
    nome = nome.replace(/_/g, ' ');
    // let tipo = nome.substring(0, 3);
    // let ano = nome.substring(4,8);
    // let mes = nome.substring(9,11);
    // let dia = nome.substring(12,14);

    // if (tipo=='AGO') {
    //   tipo = 'Assembléia Geral Ordinária realizada em '
    // }

    // if (tipo=='AGE') {
    //   tipo = 'Assembléia Geral Extraordinária realizada em  '
    // }

    // let result = tipo+' '+dia+'/'+mes+'/'+ano;


    // if (nome.length > 14) {
    //   result = result +' ('+nome.substring(15,40)+')';
    // }

    return nome;
  }

  useEffect(() => {
    // Não executar inicialmente

    // if (isFirstRun8.current) {
    //   isFirstRun8.current = false;
    //   //cl("DelNCtc first3 false")
    //   return;
    // }


    // evitar erro quando não aparecer ainda a div antes do render

    if (!document.getElementById("divpage5")) {
      setViewPdf(
        {
          'inputNome': '1',

        }

      );
      return;

    }
    //cl("DelNCtc first3", isFirstRun3)
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; }
    let ignore = false;
    let vHNomeCond = document.getElementById('Hnomecond');
    vHNomeCond.innerHTML = global.gNomecond;

    //const { chavedel, propdel } = DelProp;

    if (window.innerWidth < 800) {
      let mmenu = document.getElementById('body_base');
      mmenu.classList.remove('sidebar-open');
      mmenu.classList.add('sidebar-collapse');
      //alert('tamanho '+window.innerWidth);  
    }

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '3987MS0SJF09SFMG966505786V9UVN83CFNJH');
      bodyFormData.set('doc', global.gCond);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response8 = await axios({
        method: 'post',

        url: global.gLinkBase + '/docs.php',
        //url: 'https://jvfapp.ddns.net:3333/listNewContacs',
        // url: 'https://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (response8) {

        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; }
        //cl("resp data 8", response8['data']);


        const respprop = response8['data'];
        var element = document.getElementById("divpage5");
        //limpar div
        element.innerHTML = "";

        //add050523
        let eselectCond = document.getElementById('selectConddiv');
        if (eselectCond) {
          eselectCond.style.display = 'none';
          removeOptions(eselectCond);
          /*             let opt = document.createElement('option');
                      opt.value = 0;
                      opt.innerHTML = 'Selecione...';
                      eselectCond.appendChild(opt);
           */
          let k;
          for (k = 0; k < (global.gaListCondDistinct.length); k++) {
            let opt = document.createElement('option');
            opt.value = global.gaListCondDistinct[k].id;
            opt.innerHTML = abreviaNomeCond(global.gaListCondDistinct[k].nome);
            eselectCond.appendChild(opt);

          }
          let arrFound = gaListCondDistinct.filter(function (item, num) {
            return item.cond === global.gCond;
          });

          //cl("clarrFound",arrFound);
          eselectCond.value = arrFound[0].id;


          if (eselectCond) { eselectCond.style.display = ''; };
        }

        if (respprop['RI']) {
          txtTxtSel("Selecione o Regulamento abaixo para realizar o download (pdf)");
          const listItems = respprop['RI'].sort();

          listItems.forEach(function (item) {
            //   <div className="row" >
            //   <div className="col-md-8 col-sm-6 col-xs-12">
            //     <div className="info-box" >
            //       <span className="info-box-icon bg-aqua" ><i class="fa fa-envelope-o"></i></span>
            //       <div className="info-box-content">
            //         <span className="info-box-number">1,410</span>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            const divRow = document.createElement("div");
            divRow.setAttribute('class', 'row ')

            const div2 = document.createElement("div");
            div2.setAttribute('class', 'col-md-6 col-sm-6 col-xs-6')
            div2.setAttribute('name', 'div2')

            const div3 = document.createElement("div");
            div3.setAttribute('class', 'info-box')
            div3.setAttribute('name', 'div3')

            const span3 = document.createElement("span");
            span3.setAttribute('class', 'info-box-icon bg-aqua')
            span3.setAttribute('name', 'span3')

            const oImg = document.createElement("IMG");
            oImg.setAttribute('src', IcoPdf);
            oImg.setAttribute('alt', 'na');
            oImg.setAttribute('height', '30px');
            oImg.setAttribute('width', '30px');

            span3.appendChild(oImg);
            div3.appendChild(span3);

            const div4 = document.createElement("div");
            div4.setAttribute('class', 'info-box-content-bgg');

            const span4 = document.createElement("span");
            //span4.setAttribute('class','');
            const txtp = nomeAta(item[0]);
            const node = document.createTextNode(txtp);
            span4.appendChild(node);

            div4.appendChild(span4);

            div3.appendChild(div4);
            div2.appendChild(div3);
            divRow.appendChild(div2);


            //cl('item',item);
            const paraA = document.createElement("a");
            const parabr = document.createElement("p");
            //   const paraI = document.createElement("i");
            //   paraI.setAttribute('class', 'far fa-file-word');

            //const linkrrl = bViewPdf(global.gCond,'ATAS',item[0])
            const pasta = "RI";
            const linkurl2 = "bViewPdf(\'" + global.gCond + '\',\'' + pasta + '\',\'' + item[0] + "\'); return false;";

            paraA.onClick = "console.log('teste');";
            paraA.target = "_blank";
            paraA.setAttribute('rel', 'noopener noreferrer');
            paraA.setAttribute('class', 'btn btn-block btn-social btn-facebook');
            //paraA.setAttribute('href', '');

            global.bgg2 = function (cond, pasta, file) {
              //console.log(cond,pasta,file);
              bViewPdf(cond, pasta, file);
              //return;
            }

            const linkclick = "bgg2(\'" + global.gCond + '\',\'' + pasta + '\',\'' + item[0] + "\')";
            paraA.setAttribute('onClick', linkclick);
            //paraA.appendChild(oImg);
            paraA.appendChild(divRow);
            //parabr.appendChild(paraA);
            element.appendChild(paraA);

          });

        } else {

          txtTxtSel("Nenhum Regulamento disponível !");

        }
        let eleCardbody = document.getElementById('cardGridBggBody');
        if (eleCardbody) { eleCardbody.style.display = ''; }

      };

      if (!ignore) {
        // const redata = response['data'];
        //cl("gravou novo contato", redata);
        //setListProp({ 'chave': chavedel });
        //cl('setatu 01 01')
        //global.formAddNewContactReset();

        //setrep(redata);
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [ViewPdf])

  function txtTxtSel(a) {
    let txtC = document.getElementById('TxtSeltxt');
    txtC.textContent = a;
  }
    //add050523
    function validaSelectCond(params) {

      setSelGCond({ '0': '0' });
      
    }  
  function iconSendMail(fnome, femail, chave) {
    //ReactTabulator.table.selectRow(1);
    let ele = document.getElementById('panelEmail');
    ele.style.display = '';
    let ele2 = document.getElementById('headCEmail');
    ele2.textContent = "Destinatário: " + fnome
    let ele3 = document.getElementById('inputEmail6');
    ele3.value = femail
    let ele4 = document.getElementById('txtEmail');
    let eleCard = document.getElementById('cardGridBgg');
    eleCard.classList.add("collapsed-card");
    let eleCardI = document.getElementById('cardGridBggI');
    eleCardI.classList.remove("fa-minus");
    eleCardI.classList.add("fa-plus");
    let eleCardbody = document.getElementById('cardGridBggBody');
    eleCardbody.style.display = 'none';
    global.linkQuest = 'https://ADM.com.br/questionario_inicial/index.php?id=' + chave;
    setlinkQuest(global.linkQuest)
    ele4.value = "Caro(a) " + fnome + "\r\nObrigado por entrar em contato!\r\n" +
      "Por favor acesse o link abaixo e preencha o questionário.\r\n" +
      "Atenciosamente\r\nEquipe Dreyfus Vieira\r\n" + global.linkQuest
  }

  function cardquestion(fnome, femail, chave, resp) {
    let ele = document.getElementById('panelQuest');
    ele.style.display = 'none';
    let eleLoading = document.getElementById('divLoad');
    eleLoading.style.display = '';
    cardquestionnow(fnome, femail, chave, resp);

  }

  function geraWordProp(chave, prop) {
    const linkurl = "https://admriluk/docx.php?apikey=398738497834758934759834758934&chave=" + chave + "&prop=" + prop;
    window.open(linkurl, '_blank');

  }

  async function cardquestionnow(fnome, femail, chave, resp) {
    //document.body.style.cursor = "progress";
    //cl("cardquest", resp, chave, setCQuestion);
    if (global.chaveQuest === chave) {
      let ele = document.getElementById('panelQuest');
      ele.style.display = '';
      //document.body.style.cursor = "";
      let eleLoading = document.getElementById('divLoad');
      eleLoading.style.display = 'none';
      let eleCard = document.getElementById('cardGridBgg');
      eleCard.classList.add("collapsed-card");
      let eleCardI = document.getElementById('cardGridBggI');
      eleCardI.classList.remove("fa-minus");
      eleCardI.classList.add("fa-plus");
      let eleCardbody = document.getElementById('cardGridBggBody');
      eleCardbody.style.display = 'none';

    };
    if (resp === 'True') {
      isQuestion.current = true;
      global.chaveQuest = chave;
      await setCQuestion(chave);
      //document.body.style.cursor = "";

    }


  }

  // const DataFormatter = ({ value }) => {
  //   return dateFormat(value, "dd/mm/yy");
  // };
  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }


  function copiarlinha(linha) {
    copycli(linha, {
      debug: true,
      message: 'Press #{key} to copy',
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Linha digitável copiada !!</h1><br></br><br></br>
            <p id='pmodal'><b>{linha}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
    //clipboardy.writeSync(linha);
  }


  const printIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-print'></i>";
  };
  const iconwhatsappweb = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-whatsapp'></i>";
  }
  const iconcopy = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-copy'></i>";
  }
  const iconLixeira = function (cell, formatterParams) { //plain text value
    if (cell.getRow().getData().progress < 11) {
      return "<i class='fa fa-trash-o'></i>";
    } else {
      return "-";
    }
  }
  const emailIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-envelope-o'></i>";
  };
  const infoIcon = function (cell, formatterParams) { //plain text value
    return "<i class='fa fa-info-circle'></i> " + cell.getRow().getData().msginfo;
  };
  const wordIcon = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-file-word'></i>";
  };
  const iconContrato = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-plus-square'></i>";
  };
  const iconBarcode = function (cell, formatterParams) { //plain text value
    return "<i class='far fa-eye'></i>";
  };

  const formatPrazo = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getValue(), "dd/mm/yyyy"));
    if (cell.getValue() == null) {
      return "-"; //return the contents of the cell;  
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias < 3)) {
      return "<font color='red'><b>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</b></font>";
    } else if ((cell.getRow().getData().qtdProp > 0)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    } else if ((cell.getRow().getData().qtdProp < 1) && (tdifdias > 3)) {
      return "<font color='green'>" + dateFormat(cell.getValue(), "UTC:dd/mm/yy") + "</font>";
    }
  }

  const formatTdifdias = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    const tdifdias = calculaDias(dateFormat(Date.now(), "dd/mm/yyyy"), dateFormat(cell.getRow().getData().prazo, "dd/mm/yyyy"));
    if ((cell.getRow().getData().qtdProp < 1) && (cell.getRow().getData().prazo !== null)) {
      if (tdifdias >= 4) {
        return "<font color='green'>" + tdifdias + "</font>";
      } else if ((tdifdias > 0) && (tdifdias <= 3)) {
        return "<font color='red'>" + tdifdias + "</font>";
      } else if ((tdifdias <= 0)) {
        return "<font color='red'><b>" + tdifdias + "</b></font>";
      }
    } else {
      return "";
    }

  }

  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    { title: 'Id', field: 'id', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Condomínio', field: 'nomecond', align: 'center', width: 280, responsive: 0 },
    { title: 'Bloco', field: 'bloco', align: 'center', width: 50, responsive: 0, headerSort: false },
    { title: 'Unid.', field: 'unid', align: 'center', width: 50, responsive: 0, headerSort: false },
    {
      title: 'Vencimento', field: 'vencto', align: 'center', width: 105, responsive: 0, formatter: "datetime", formatterParams: {
        inputFormat: "YYYY-MM-DD",
        outputFormat: "DD/MM/YY",
        invalidPlaceholder: "(invalid date)",
      }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    },
    //{
    //  title: 'Data Limite', field: 'dtlimite', align: 'center', width: 105, responsive: 0, formatter: formatPrazo, formatterParams: {
    //    inputFormat: "YYYY-MM-DD",
    //    outputFormat: "DD/MM/YY",
    //    invalidPlaceholder: "-",
    //  }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    //},
    //{ title: '<center><i class=\'fa fa-clock-o\'></i></center>', formatter: formatTdifdias, align: "center", width: 20, responsive: 0,  headerSort: false},
    //{ title: '<center><i class=\'far fa-file-word\'></i></center>', align: "center", field: 'qtdProp', width: 20, responsive: 0, sorter: "number", headerSort: false,cellClick: function (e, cell) {
    //   if (cell.getRow().getData().qtdProp >0 ) {
    //     cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp)
    //     ListarProp();
    //    };
    //   }  },
    // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    //{ title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
    //{ title: 'Fone', field: 'fone', align: 'center', width: 100, responsive: 0 },
    { title: 'R$', field: 'valor2', align: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Linha Digitavel', field: 'linha_digitavel', align: 'center', width: 390, responsive: 0 },
    //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', align: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, align: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', formatter: emailIcon, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { iconSendMail(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
    { formatter: iconcopy, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { copiarlinha(cell.getRow().getData().linha_digitavel) } },
    { formatter: iconBarcode, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { gerarboleto('', cell.getRow().getData().id) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { gerarboleto('1', cell.getRow().getData().id) } },
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', align: 'center' },
  ];

  const columnsProp = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    //{ title: 'Id', field: 'id', align: 'center', width: 40, visible: false, responsive: 0 },
    // {
    //  title: 'Data', field: 'createdAt', align: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
    //    inputFormat: "YYYY-MM-DD",
    //   outputFormat: "DD/MM/YY",
    //   invalidPlaceholder: "(invalid date)",
    // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
    // },
    // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    { title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Tipo', field: 'tipo', align: "center", width: 200, responsive: 0, },
    { title: 'Numero', field: 'prop', align: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Valor Total', field: 'vlr_total', align: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'Parcelas', field: 'parc', align: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
    { title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
    { title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
    { title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
    { title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { gGlobal.gContrato = cell.getRow().getData().prop; gerContratoLink(); } },
    //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    //{ title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
    //{ title: 'Fone', field: 'fone', align: 'center', width: 130, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Origem', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', align: 'center' },
  ];

  // const data = [
  //   { id: 1, name: 'Oli Bob', age: '12', color: 'red', dob: '01/01/1980', rating: 5, passed: true, pets: ['cat', 'dog'] },
  //   { id: 2, name: 'Mary May', age: '1', color: 'green', dob: '12/05/1989', rating: 4, passed: true, pets: ['cat'] },
  //   { id: 3, name: 'Christine Lobowski', age: '42', color: 'green', dob: '10/05/1985', rating: 4, passed: false },
  //   { id: 4, name: 'Brendon Philips', age: '125', color: 'red', dob: '01/08/1980', rating: 4.5, passed: true },
  //   { id: 5, name: 'Margret Marmajuke', age: '16', color: 'yellow', dob: '07/01/1999', rating: 4, passed: false },
  //   {
  //     id: 6,
  //     name: 'Van Ng',
  //     age: '37',
  //     color: 'green',
  //     dob: '06/10/1982',
  //     rating: 4,
  //     passed: true,
  //     pets: ['dog', 'fish']
  //   },
  //   { id: 7, name: 'Duc Ng', age: '37', color: 'yellow', dob: '10/10/1982', rating: 4, passed: true, pets: ['dog'] }
  // ];

  // // Editable Example:
  // const colorOptions = { '': '&nbsp;', red: 'red', green: 'green', yellow: 'yellow' };
  // const petOptions = [{ id: 'cat', name: 'cat' }, { id: 'dog', name: 'dog' }, { id: 'fish', name: 'fish' }];
  const options = {
    height: 150,
    // movableRows: true,
    clipboard: true,
    invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "vencto", dir: "desc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };
  // function doSomething() {
  //   //cl('test');
  // }

  // function doSomethingInterval() {
  //   doSomething();
  //   setTimeout(doSomethingInterval, 5000);
  // }

  // doSomethingInterval();


  if (rep.length < 1) {
    return (
      <div className="content-wrapper">
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando dados...</div>
      </div>
    )
  } else {
    return (
      <div className="content-wrapper">
        {ViewDocs()}
        {/* {page1()} */}
        {/* {page2()} */}
        {/* {page3()} */}
        {/* {page4()} */}
        {/* {page5()} */}
      </div>
    )

  }
}

export default Gerador